import React, { useState, useEffect } from "react";
import { Typography, Box, CircularProgress } from "@mui/material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ReplaceLead from "./components/ReplaceLead";
import decode from "../../../general/util/jwtDecode";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import customFetch from "../../../general/auth/customFetch";

import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function AgentLeads() {
  const currentUser = decode();
  const [leads, setLeads] = useState([]);
  const [products, setProducts] = useState([]);
  const [customFieldKeys, setCustomFieldKeys] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function parseISODateStringNoTimezone(isoString) {
    if (!isoString) return null;

    const localISOString = isoString.replace("Z", "");
    const date = new Date(localISOString);

    return isNaN(date.getTime()) ? null : date;
  }

  const getLeads = async () => {
    try {
      if (!currentUser) {
        window.location = "/login";
        return;
      } else if (currentUser.role === "suspended") {
        window.location = "/suspended";
        return;
      }

      const response = await customFetch(
        `/v1/leads/user/${currentUser.tenant_user_id}`,
        {
          method: "GET",
          credentials: "include",
          withCredentials: true,
        }
      );
      const jsonData = await response.json();
      const processedData = jsonData.map((item) => ({
        ...item,
        dob: item.dob ? parseISODateStringNoTimezone(item.dob) : null,
      }));
      setLeads(processedData);

      const fieldKeys = new Set();
      jsonData.forEach((lead) => {
        Object.keys(lead.custom_fields || {}).forEach((key) => {
          fieldKeys.add(key);
        });
      });
      setCustomFieldKeys([...fieldKeys]);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getProducts = async () => {
    try {
      const response = await customFetch(`/v1/products/product-catalog`, {
        method: "GET",
      });
      const jsonData = await response.json();
      setProducts(jsonData);
    } catch (error) {
      console.error(error.message);
    }
  };

  const getProductName = (productId) => {
    const product = products.find((p) => p.id === productId);
    return product ? product.name : "";
  };

  const getProductKey = (productId) => {
    const product = products.find((p) => p.id === productId);
    return product ? product.product_key : "";
  };

  useEffect(() => {
    // Fetch leads and products
    getLeads();
    getProducts();
  }, []);

  useEffect(() => {
    if (customFieldKeys.length > 0) {
      // Now that customFieldKeys is available, set the column visibility model
      const defaultVisibilityModel = {
        phone: false,
        dob: false,
        city: false,
        zip: false,
        timezone: false,
        email: false,
      };

      const initialCustomFieldsVisibility = customFieldKeys.reduce(
        (model, key) => {
          model[`custom_fields.${key}`] = false; // Default to false (invisible)
          return model;
        },
        {}
      );

      const mergedVisibilityModel = {
        ...defaultVisibilityModel,
        ...initialCustomFieldsVisibility,
      };

      setColumnVisibilityModel(mergedVisibilityModel);
      setLoading(false); // Data is ready, so we stop loading
    }
  }, [customFieldKeys]);

  const columns = [
    {
      field: "ref_order",
      headerName: "Order ID",
      flex: 0.5,
      type: "number",
      align: "left",
      headerAlign: "left",
    },
    { field: "created", headerName: "Created", flex: 0.65, type: "date" },
    { field: "name", headerName: "Lead Name", flex: 1.1 },
    { field: "state", headerName: "State", flex: 0.5 },
    { field: "dob", headerName: "Dob", type: "date", flex: 0.5 },
    { field: "city", headerName: "City", flex: 0.5 },
    { field: "zip", headerName: "Zip", flex: 0.5 },
    { field: "timezone", headerName: "Timezone", flex: 0.5 },
    {
      field: "product_id",
      headerName: "Product",
      flex: 1,
      renderCell: (params) => getProductName(params.row.product_id),
    },
    { field: "phone", headerName: "Phone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1.5 },
    ...customFieldKeys.map((key) => ({
      field: `custom_fields.${key}`,
      headerName: key.charAt(0).toUpperCase() + key.slice(1),
      flex: 0.5,
      valueGetter: (params, row) => row.custom_fields[key] || "",
    })),
    {
      field: "actions",
      sortable: false,
      filterable: false,
      headerName: "Actions",
      width: 140,
      renderCell: (params) => (
        <ReplaceLead
          lead={params.row}
          product_key={getProductKey(params.row.product_id)}
        />
      ),
    },
  ];

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ width: "100%", p: 3 }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "center",
          }}
        >
          Leads
        </Typography>
        <Box sx={{ width: "100%" }}>
          <DataGridPro
            rows={leads.map((lead) => ({
              ...lead,
              created: new Date(lead.created),
            }))}
            columns={columns}
            initialState={{
              columns: {
                columnVisibilityModel: columnVisibilityModel,
              },
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            autoHeight
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            pagination
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
}
