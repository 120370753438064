import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Alert,
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
  ListItemText,
  Container,
  Tabs,
  Tab,
  Divider,
  Stack,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import { statesList } from "../../../general/util/statesList";
import LeadsByStateAndAgeChart from "../../../general/charts/LeadsByStateAndAgeChart";

const ClaimOverrunLeads = () => {
  const currentUser = decode();
  const [products, setProducts] = useState([]);
  const [overrunProducts, setOverrunProducts] = useState([]);
  const [leadProducts, setLeadProducts] = useState([]);
  const [overrunOrders, setOverrunOrders] = useState([]);
  const [claims, setClaims] = useState([]);
  const [availableTokens, setAvailableTokens] = useState(0);
  const [leadsRequest, setLeadsRequest] = useState({
    "0-2days": 0,
    "2days-7days": 0,
    "7days-30days": 0,
    "30days-90days": 0,
    "90days+": 0,
  });
  const [selectedStates, setSelectedStates] = useState([]);
  const [overrunProductId, setOverrunProductId] = useState("");
  const [openClaimDialog, setOpenClaimDialog] = useState(false);
  const [openPurchaseDialog, setOpenPurchaseDialog] = useState(false);
  const [purchaseTokens, setPurchaseTokens] = useState(10);
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [acceptNoRefunds, setAcceptNoRefunds] = useState(false);
  const [acceptNoReplacements, setAcceptNoReplacements] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    if (overrunProducts.length > 0) {
      fetchOverrunOrders();
      fetchAvailableTokens();
      fetchClaimsByProduct();
    }
  }, [overrunProducts]);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(`/v1/products/product-catalog`);
      const data = await response.json();
      const overrunProducts = data.filter(
        (product) => product.product_key === "agent-overrun"
      );
      const leadProducts = data.filter(
        (product) => product.product_key === "agent-lead"
      );
      setProducts(data);
      setOverrunProducts(overrunProducts);
      setLeadProducts(leadProducts);
      if (overrunProducts.length > 0) {
        setOverrunProductId(overrunProducts[0].id);
      }
    } catch (error) {
      console.error("Error fetching products", error);
      setError("Failed to fetch products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchOverrunOrders = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(`/v1/orders/overruns`);
      const data = await response.json();
      const ordersWithProductNames = data.map((order) => ({
        ...order,
        product_id:
          products.find((p) => p.id === order.product_id)?.name ||
          order.product_id,
        created_time: new Date(order.created_time),
      }));
      setOverrunOrders(ordersWithProductNames);
    } catch (error) {
      console.error("Error fetching overrun orders", error);
      setError("Failed to fetch overrun orders. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvailableTokens = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(
        `/v1/orders/overruns/available-tokens`
      );
      const data = await response.json();
      setAvailableTokens(data.availableTokens);
    } catch (error) {
      console.error("Error fetching available tokens", error);
      setError("Failed to fetch available tokens. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClaimsByProduct = async () => {
    setIsLoading(true);
    try {
      const response = await customFetch(`/v1/orders/overruns/claims`);
      const data = await response.json();
      const claimsbyProduct = data.map((claim) => ({
        ...claim,
        product_id:
          products.find((p) => p.id === claim.product_id)?.name ||
          claim.product_id,
      }));
      setClaims(claimsbyProduct);
    } catch (error) {
      console.error("Error fetching claims", error);
      setError("Failed to fetch claims. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLeadsRequestChange = (event) => {
    let value = parseInt(event.target.value, 10);
    value = isNaN(value) || value < 0 ? 0 : value;
    setLeadsRequest({
      ...leadsRequest,
      [event.target.name]: value,
    });
  };

  const handleStatesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStates(typeof value === "string" ? value.split(",") : value);
  };

  const handleClaimLeads = async () => {
    setIsLoading(true);
    const requestBody = {
      leads_request: leadsRequest,
      states: selectedStates,
      overrun_product_id: overrunProductId,
    };

    try {
      const response = await customFetch(`/v1/orders/overruns/claims`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        fetchAvailableTokens();
        fetchClaimsByProduct();
        handleCloseClaimDialog();
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error("Error claiming leads", error);
      setError("An error occurred while claiming leads.");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePurchaseTokens = async () => {
    setIsLoading(true);
    const requestBody = {
      total_tokens_ordered: purchaseTokens,
      accept_no_refunds: acceptNoRefunds,
      accept_no_replacements: acceptNoReplacements,
      successUrl: window.location.origin + "/overruns",
    };

    try {
      const response = await customFetch(`/v1/orders/overruns`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      if (response.ok) {
        const data = await response.json();
        window.location.href = data.redirect; // Redirect to Stripe checkout page
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error("Error purchasing tokens", error);
      setError("An error occurred while purchasing tokens.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenClaimDialog = () => {
    setOpenClaimDialog(true);
  };

  const handleCloseClaimDialog = () => {
    setOpenClaimDialog(false);
    setError("");
  };

  const handleOpenPurchaseDialog = () => {
    setOpenPurchaseDialog(true);
  };

  const handleClosePurchaseDialog = () => {
    setOpenPurchaseDialog(false);
    setError("");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setOverrunProductId(overrunProducts[newValue].id);
  };

  const columns = [
    { field: "id", headerName: "Token Order ID", flex: 0.75 },
    { field: "total_tokens_ordered", headerName: "Tokens Purchased", flex: 1 },
    { field: "order_status", headerName: "Order Status", flex: 1 },
    { field: "created_time", headerName: "Order Date", flex: 1, type: "date" },
  ];

  const claimColumns = [
    { field: "order_id", headerName: "Reference Order", flex: 1 },
    { field: "product_id", headerName: "Product", flex: 1 },
    { field: "tokens_used", headerName: "Tokens Used", flex: 1 },
    { field: "total_leads", headerName: "Leads Claimed", flex: 1 },
    { field: "states", headerName: "States", flex: 1 },
  ];

  const tokenCosts = {
    "0-2days": 5,
    "2days-7days": 4,
    "7days-30days": 3,
    "30days-90days": 2,
    "90days+": 1,
  };

  const totalTokens = Object.entries(leadsRequest).reduce(
    (acc, [key, value]) =>
      acc + (tokenCosts[key] || 0) * (isNaN(value) ? 0 : value),
    0
  );

  return (
    <div className="content">
      <Container disableGutters sx={{ width: "100vp", padding: 0, margin: 0 }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Claim Overrun Leads
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Overrun leads are leads which have been generated by BIAB but didn't
          have an active order to assign them to. They are a result of how
          facebook turns off advertisements which sometimes results in more
          leads being generated than expected.
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 0.5, lineHeight: "1.2" }}>
          When claiming overrun leads, each lead type has a specific token cost
          associated with it. Here is a breakdown to help you understand the
          value:
        </Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 6,
            "& .MuiListItem-root": { display: "list-item", py: 0 },
          }}
        >
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads older than 90 days cost 1 token each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 30-90 days old cost 2 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 7-30 days old cost 3 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 2-7 days old cost 4 tokens each." />
          </ListItem>
          <ListItem sx={{ py: 0 }}>
            <ListItemText primary="Leads 0-2 days old cost 5 tokens each." />
          </ListItem>
        </List>
        <Typography variant="body1" sx={{ mt: 0.5, lineHeight: "1.2" }}>
          This means that newer leads require more tokens, reflecting their
          recent activity and higher potential value.
        </Typography>
      </Container>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Card sx={{ minWidth: 275, marginBottom: 1, marginTop: 3 }}>
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" component="div" sx={{ marginBottom: 2 }}>
                Available Tokens: {availableTokens}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenPurchaseDialog}
                disabled={isLoading}
              >
                {isLoading ? "Loading..." : "Purchase Tokens"}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ width: "100%", margin: "20px 0px" }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Token Orders
        </Typography>
        <DataGridPro
          rows={overrunOrders}
          columns={columns}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          pagination
          autoHeight
        />
      </Box>
      <Box sx={{ width: "100%", margin: "20px 0px" }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Claims
        </Typography>
  
        <Box display="flex" justifyContent="left" sx={{ margin: "20px 0px" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenClaimDialog}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Claim Leads"}
          </Button>
        </Box>
        <DataGridPro
          rows={claims}
          columns={claimColumns}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          pagination
          autoHeight
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 2,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Overrun Product Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {overrunProducts.map((product, index) => (
              <Tab key={product.id} label={product.name} />
            ))}
          </Tabs>
        </Box>
        <LeadsByStateAndAgeChart productId={overrunProductId} />
      </Box>
  
      <Dialog open={openClaimDialog} onClose={handleCloseClaimDialog}>
        <DialogTitle>Claim Overrun Leads</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the number of leads to claim and the states you want to
            target.
          </DialogContentText>
          <TextField
            select
            margin="dense"
            label="Overrun Product ID"
            fullWidth
            value={overrunProductId}
            onChange={(e) => setOverrunProductId(e.target.value)}
          >
            {overrunProducts.map((product) => (
              <MenuItem key={product.id} value={product.id}>
                {product.name}
              </MenuItem>
            ))}
          </TextField>
          {Object.keys(leadsRequest).map((key) => (
            <TextField
              key={key}
              margin="dense"
              label={`${key} Leads`}
              type="number"
              fullWidth
              name={key}
              value={leadsRequest[key]}
              onChange={handleLeadsRequestChange}
            />
          ))}
          <FormControl fullWidth margin="dense">
            <InputLabel>States</InputLabel>
            <Select
              label="States"
              multiple
              value={selectedStates}
              onChange={handleStatesChange}
              renderValue={(selected) => selected.join(", ")}
            >
              {statesList.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  <Checkbox
                    checked={selectedStates.indexOf(state.value) > -1}
                  />
                  <ListItemText primary={state.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Divider sx={{ marginY: 2 }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Total Tokens Used:</Typography>
            <Typography variant="h6" color="primary">
              {totalTokens}
            </Typography>
          </Stack>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClaimDialog} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleClaimLeads} color="primary" disabled={isLoading}>
            {isLoading ? "Claiming..." : "Claim Leads"}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPurchaseDialog} onClose={handleClosePurchaseDialog}>
        <DialogTitle>Purchase Tokens</DialogTitle>
        <DialogContent>
          <DialogContentText mb={1}>
            Select the overrun product and specify the number of tokens to
            purchase. The order must be a minimum of 10 tokens. Tokens are $3
            each.
          </DialogContentText>
          <TextField
            margin="dense"
            label="Number of Tokens"
            type="number"
            fullWidth
            value={purchaseTokens}
            onChange={(e) => setPurchaseTokens(parseInt(e.target.value, 10))}
            error={purchaseTokens < 10}
            helperText={purchaseTokens < 10 ? "Minimum order is 10 tokens" : ""}
          />
          <FormControl fullWidth margin="dense">
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptNoRefunds}
                  onChange={(e) => setAcceptNoRefunds(e.target.checked)}
                  color="primary"
                />
              }
              label="I acknowledge there are no refunds on tokens purchased however tokens never expire."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptNoReplacements}
                  onChange={(e) => setAcceptNoReplacements(e.target.checked)}
                  color="primary"
                />
              }
              label="I acknowledge overrun leads are not eligible for replacement."
            />
          </FormControl>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {error && (
            <Alert severity="error" sx={{ marginTop: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePurchaseDialog} color="primary" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handlePurchaseTokens}
            color="primary"
            disabled={
              isLoading ||
              purchaseTokens < 10 ||
              !acceptNoRefunds ||
              !acceptNoReplacements
            }
          >
            {isLoading ? "Processing..." : "Purchase Tokens"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClaimOverrunLeads;
