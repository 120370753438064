import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { statesList } from "../../../../general/util/statesList";
import { orderStatusList } from "../../../../general/util/orderStatusList";
import customFetch from "../../../../general/auth/customFetch";
import decode from "../../../../general/util/jwtDecode";

const NEW_PRODUCT_KEYS = [
  "tenant-ad-set",
  "tenant-campaign-state",
  "tenant-campaign-state-county",
];

export default function CreateTenantProductOrder({
  tenantUsersList,
  updateOrders,
  productCatalog,
}) {
  const [tenantUserId, setTenantUserId] = useState("");
  const [numLeads, setNumLeads] = useState(30);
  const [dailyCap, setDailyCap] = useState(20);
  const [status, setStatus] = useState("pending");
  const [startDate, setStartDate] = useState(null);
  const [orderDate, setOrderDate] = useState(new Date());
  const [states, setStates] = useState([]);
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState("");
  const [productKey, setProductKey] = useState("");
  const [errors, setErrors] = useState({});
  const currentUser = decode();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProductChange = (event) => {
    const selectedProductId = event.target.value;
    setProduct(selectedProductId);

    const selectedProduct = productCatalog.find(
      (product) => product.id === selectedProductId
    );
    setProductKey(selectedProduct?.product_key || "");

    if (selectedProduct?.product_key !== "tenant-campaign-state") {
      setStates([]);
    }
  };

  const createOrder = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({});

    // Validate fields
    let newErrors = {};
    if (!tenantUserId) newErrors.tenantUserId = "User is required";
    if (!product) newErrors.product = "Product is required";
    if (!numLeads || numLeads <= 0)
      newErrors.numLeads = "Number of leads must be greater than 0";
    if (!dailyCap || dailyCap <= 0)
      newErrors.dailyCap = "Daily cap must be greater than 0";
    if (!status) newErrors.status = "Order status is required";
    if (!orderDate) newErrors.orderDate = "Order date is required";

    // Check if states are required and validate
    if (
      productKey === "tenant-campaign-state" &&
      (!states || states.length === 0)
    ) {
      newErrors.states = "At least one state is required for this product";
    }

    // If there are errors, set them and stop the submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      let body = {
        tenant_user_id: tenantUserId,
        product_id: product,
        num_leads: numLeads,
        daily_cap: dailyCap,
        order_status: status,
        order_date: orderDate,
        start_date: startDate,
        states: productKey === "tenant-campaign-state" ? states : null,
      };
      const response = await customFetch(
        `/v1/tenantadmin/${currentUser.tenant_id}/orders`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        }
      );
      const newOrder = await response.json();
      updateOrders(newOrder);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const eligibleProducts = productCatalog.filter((product) =>
    NEW_PRODUCT_KEYS.includes(product.product_key)
  );

  if (eligibleProducts.length === 0) {
    return null;
  }

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleShow}>
        Create New Tenant Order
      </Button>

      <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Create a New Tenant Order</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            id="create-tenant-order"
            noValidate
            autoComplete="off"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Autocomplete
              options={tenantUsersList}
              getOptionLabel={(option) =>
                `${option.id} - ${option.first_name} ${option.last_name} (${option.email})`
              }
              value={
                tenantUsersList.find((option) => option.id === tenantUserId) ||
                null
              }
              onChange={(e, newValue) =>
                setTenantUserId(newValue ? newValue.id : "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User"
                  margin="dense"
                  required
                  error={!!errors.tenantUserId}
                  helperText={errors.tenantUserId}
                />
              )}
            />

            <TextField
              select
              label="Product"
              value={product}
              onChange={handleProductChange}
              required
              SelectProps={{
                native: false,
              }}
              error={!!errors.product}
              helperText={errors.product || ""}
            >
              {eligibleProducts.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              label="Number of Leads"
              type="number"
              required
              value={numLeads}
              onChange={(e) => setNumLeads(e.target.value)}
              inputProps={{ min: 0 }}
              error={!!errors.numLeads}
              helperText={errors.numLeads}
            />

            <TextField
              label="Daily Cap"
              type="number"
              required
              value={dailyCap}
              onChange={(e) => setDailyCap(e.target.value)}
              inputProps={{ min: 0 }}
              error={!!errors.dailyCap}
              helperText={errors.dailyCap}
            />

            <Autocomplete
              options={orderStatusList}
              getOptionLabel={(option) => option.label}
              value={
                orderStatusList.find((option) => option.value === status) ||
                null
              }
              onChange={(e, newValue) =>
                setStatus(newValue ? newValue.value : "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Order Status"
                  required
                  error={!!errors.status}
                  helperText={errors.status}
                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Order Date"
                inputFormat="MM/dd/yyyy"
                value={orderDate}
                onChange={setOrderDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    error={!!errors.orderDate}
                    helperText={errors.orderDate}
                  />
                )}
              />
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/dd/yyyy"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>

            {productKey === "tenant-campaign-state" && (
              <Autocomplete
                multiple
                options={statesList}
                getOptionLabel={(option) => option.label}
                value={states.map((state) =>
                  statesList.find((s) => s.value === state)
                )}
                onChange={(e, newValue) =>
                  setStates(newValue.map((item) => item.value))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="States"
                    required
                    error={!!errors.states}
                    helperText={errors.states}
                  />
                )}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={createOrder} color="primary">
            Create Order
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
