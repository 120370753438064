import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Button,
  IconButton,
  Card,
  CardContent,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";
import CreateSchedule from "./components/CreateSchedule";
import EditSchedule from "./components/EditSchedule";
import DeleteSchedule from "./components/DeleteSchedule";
import moment from "moment-timezone";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const ScheduleList = () => {
  const currentUser = decode();
  const [schedules, setSchedules] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(0);
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);
  const [usedStates, setUsedStates] = useState([]);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [allSchedulesDisabled, setAllSchedulesDisabled] = useState(false);

  useEffect(() => {
    fetchProducts();
    fetchOrders();
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      fetchSchedules(products[selectedProduct].id);
    }
  }, [products, selectedProduct]);

  const fetchProducts = async () => {
    try {
      const response = await customFetch(`/v1/products/product-catalog`);
      const data = await response.json();
      const relevantProducts = data.filter(
        (product) =>
          product.product_key === "agent-lead" ||
          product.product_key === "agent-adset" ||
          product.product_key === "tenant-campaign-state" ||
          product.product_key === "tenant-ad-set"
      );
      setProducts(relevantProducts);
      if (relevantProducts.length > 0) {
        setSelectedProduct(0);
      }
    } catch (error) {
      console.error("Error fetching products", error);
    }
  };

  const fetchSchedules = async (productId) => {
    try {
      const response = await customFetch(
        `/v1/users/${currentUser.tenant_user_id}/products/${productId}/schedules`
      );
      const data = await response.json();
      setSchedules(data);

      const selectedProductKey = products.find(
        (product) => product.id === productId
      )?.product_key;

      const usedStates = [];
      let allDisabled = true;

      data.forEach((schedule) => {
        if (
          selectedProductKey === "tenant-campaign-state" ||
          selectedProductKey === "agent-lead"
        ) {
          schedule.states.forEach((state) => {
            usedStates.push(state);
          });
        }
        if (schedule.is_active) {
          allDisabled = false;
        }
      });

      setUsedStates(usedStates);
      setAllSchedulesDisabled(allDisabled);
    } catch (error) {
      console.error("Error fetching schedules", error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await customFetch(`/v1/orders/`);
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error("Error fetching orders", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedProduct(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (params) => {
    setSelectedSchedule(params.row);
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedSchedule(null);
  };

  const handleDeleteClick = (id) => {
    setSelectedDeleteId(id);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    setOpenDelete(false);
    setSelectedDeleteId(null);
  };

  const handleHelpDialogOpen = () => {
    setHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  };

  const selectedProductKey = products[selectedProduct]?.product_key;
  const showStates =
    selectedProductKey === "tenant-campaign-state" ||
    selectedProductKey === "agent-lead";

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    {
      field: "start_time",
      headerName: "Start Time",
      flex: 0.7,
      renderCell: (params) =>
        moment(params.value, "HH:mm:ssZ").format("hh:mm A"),
    },
    {
      field: "end_time",
      headerName: "End Time",
      flex: 0.7,
      renderCell: (params) =>
        moment(params.value, "HH:mm:ssZ").format("hh:mm A"),
    },
    { field: "timezone", headerName: "Timezone", flex: 1 },
    showStates && { field: "states", headerName: "States", flex: 1 },
    { field: "active_days", headerName: "Active Days", flex: 1 },
    { field: "is_active", headerName: "Active", type: "boolean", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEditClick(params)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteClick(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ].filter(Boolean);

  const getMissingStates = () => {
    if (
      selectedProductKey !== "tenant-campaign-state" &&
      selectedProductKey !== "agent-lead"
    ) {
      return [];
    }

    const orderStates = orders
      .filter((order) => order.product_id === products[selectedProduct].id)
      .map((order) => order.states)
      .flat();
    const scheduleStates = schedules.map((schedule) => schedule.states).flat();
    return [
      ...new Set(
        orderStates.filter((state) => !scheduleStates.includes(state))
      ),
    ];
  };

  const convertTo12HourFormat = (time) => {
    const [hour, minute] = time.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const hour12 = hourInt % 12 || 12;
    return `${hour12}:${minute} ${ampm}`;
  };

  const missingStates = getMissingStates();
  const showStateAlerts =
    selectedProductKey === "tenant-campaign-state" ||
    selectedProductKey === "agent-lead";

  return (
    <div className="content">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{ color: "primary.main", marginBottom: 2, textAlign: "center" }}
        >
          Schedule List
        </Typography>
        <IconButton
          onClick={handleHelpDialogOpen}
          sx={{
            position: "absolute",
            bottom: 16,
            right: 16,
            width: 64,
            height: 64,
          }}
        >
          <HelpOutlineIcon sx={{ width: "100%", height: "100%" }} />
        </IconButton>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedProduct} onChange={handleTabChange}>
          {products.map((product) => (
            <Tab key={product.id} label={product.name} />
          ))}
        </Tabs>
      </Box>
      {schedules.length > 0 && allSchedulesDisabled && (
        <Alert severity="error" sx={{ margin: "20px 0px" }}>
          No Leads are delivered when all schedules are disabled.
        </Alert>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "20px 0px" }}
      >
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Create Schedule
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "20px 0px" }}
      >
        <Card sx={{ minWidth: 275, marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {products[selectedProduct]?.name} Campaign Schedule
            </Typography>
            {products[selectedProduct]?.start_time &&
            products[selectedProduct]?.end_time ? (
              <>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Start Time:{" "}
                  {convertTo12HourFormat(products[selectedProduct].start_time)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  End Time:{" "}
                  {convertTo12HourFormat(products[selectedProduct].end_time)}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Active Days:{" "}
                  {products[selectedProduct].active_days
                    ? products[selectedProduct].active_days.join(", ")
                    : "N/A"}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="textSecondary">
                  Time Zone: {products[selectedProduct].time_zone}
                </Typography>
              </>
            ) : (
              <Typography sx={{ mb: 1.5 }} color="textSecondary">
                This product runs 24 hours.
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>

      {schedules.length === 0 ? (
        <Alert severity="info">
          No schedule is created for this product. All orders will follow the
          campaign schedule.
        </Alert>
      ) : (
        <>
          {showStateAlerts && missingStates.length > 0 && (
            <>
              <Alert severity="warning">
                The following states are in orders but don't have a schedule
                created: {missingStates.join(", ")}
              </Alert>
              <Alert severity="error" sx={{ margin: "20px 0px" }}>
                Leads are delivered to active schedules and active states only.
              </Alert>
            </>
          )}
          <Box sx={{ width: "100%", margin: "20px 0px" }}>
            <DataGridPro
              rows={schedules}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10, 20, 50]}
            />
          </Box>
        </>
      )}
      <CreateSchedule
        open={open}
        handleClose={handleClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        usedStates={usedStates}
      />

      <EditSchedule
        openEdit={openEdit}
        handleEditClose={handleEditClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        selectedSchedule={selectedSchedule}
        setSelectedSchedule={setSelectedSchedule}
        usedStates={usedStates}
      />

      <DeleteSchedule
        openDelete={openDelete}
        handleDeleteClose={handleDeleteClose}
        fetchSchedules={fetchSchedules}
        products={products}
        selectedProduct={selectedProduct}
        selectedDeleteId={selectedDeleteId}
        setSelectedDeleteId={setSelectedDeleteId}
      />

      <Dialog open={helpDialogOpen} onClose={handleHelpDialogClose}>
        <DialogTitle>Schedule Help</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Here you can manage the schedules for each product. Use the tabs to
            switch between products and view their respective schedules. You can
            create, edit, and delete schedules using the buttons and icons
            provided.
            <ul>
              <li>
                <strong>Create Schedule:</strong> Click the "Create Schedule"
                button to add a new schedule for the selected product.
              </li>
              <li>
                <strong>Edit Schedule:</strong> Use the edit icon next to each
                schedule to change its details.
              </li>
              <li>
                <strong>Delete Schedule:</strong> Use the delete icon next to
                each schedule to remove it.
              </li>
            </ul>
            <Alert severity="warning" sx={{ margin: "20px 0px" }}>
              If a product does not have a schedule, all orders will follow the
              default campaign schedule. Make sure to create schedules for any
              specific times and states where you want to receive those leads.
            </Alert>
            <Alert severity="error" sx={{ margin: "20px 0px" }}>
              Leads are delivered only to active schedules and active states.
              You are responsible for ensuring that schedules cover all the
              states you want to advertise in. If an order includes states that
              do not have schedules, those states may not receive any leads.
            </Alert>
            <Alert severity="info" sx={{ margin: "20px 0px" }}>
              You can choose to disable or delete all schedules. If you do this,
              ads will follow the main campaign schedule instead.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ScheduleList;
